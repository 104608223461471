import React from "react";
import seekeriphonehold from "../Images/Branding/seekeriphonehold.png";
import Pendingjobs from "../Images/Features/Pendingjobs.png";

const Serviceproshero = () => {
	return (
		<div className="container serviceproherosection" id="serviceProSection">
			<div className="row branding-containervideo">
				<div className="col-md-4 herovideo">
					<h1 className="videoandimagediv lilitaonered">
						For Service Providers
					</h1>
					<p>
						Grow your business with our platform's best-in-class
						capabilities. Submit proposals, send and execute service
						contracts, schedule services, and receive payment all in
						one convenient platform. And best of all...
						<strong id="underline">
							only pay for closed leads.
						</strong>
					</p>
					{/* <iframe
						className="videoandimagediv iframe"
						src="https://www.youtube.com/embed/LMlCN6_vUvs?si=W3G8Fqb26HlVi-4B"
						title="YouTube video player"
						frameBorder=""
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					></iframe> */}
					<div className="videoandimagediv">
						<img className="servicepicture" src={Pendingjobs} />
					</div>
				</div>
				<div className="col-md-8 phonedislay">
					<img className="providerphone" src={seekeriphonehold} />
				</div>
			</div>
		</div>
	);
};

export default Serviceproshero;
