import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAXk2B6fib4N7YgdRnOX5-ameWZiWYrVw",
  authDomain: "redi-c084d.firebaseapp.com",
  projectId: "redi-c084d",
  storageBucket: "redi-c084d.appspot.com",
  messagingSenderId: "580031032149",
  appId: "1:580031032149:web:f1823b7b8b5e7ca8a15ddc",
  measurementId: "G-BLZS0C6PMW",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
