import React from 'react'

const Makemoneyhero = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      const offsetTop = section.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
  }
  return (
    <div className="container ftco-section bg-light" id="pricingSection">
      <div className="row justify-content-center pb-2">
        <div className="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
          <h2>Flexible Pricing Based on Your Needs</h2>
          <p>*Free for Real Estate Investors</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 ftco-animate fadeInUp ftco-animated">
          <div className="block-7">
            {/* <div
								className="img"
								style="background-image: url(https://www.bootdey.com/image/350x280/FFB6C1/000000);"
							></div> */}
            <div className="text-center p-4">
              <span className="excerpt d-block">Only Pay For Leads Closed</span>
              <span className="price">
                <sup>%</sup> <span className="number">2</span>{' '}
                <sub>/new lead</sub>
              </span>
              <ul className="pricing-text mb-5">
                <li>
                  <span className="fa fa-check mr-2"></span>
                  Messaging
                </li>
                <li style={{ textDecoration: 'line-through' }}>
                  <span className="fa fa-check mr-2"></span>
                  Scheduling
                </li>
                <li style={{ textDecoration: 'line-through' }}>
                  <span className="fa fa-check mr-2"></span>
                  Negotiation
                </li>
                <li style={{ textDecoration: 'line-through' }}>
                  <span className="fa fa-check mr-2"></span>
                  Invoicing
                </li>
              </ul>

              {/* <a
								href="#"
								className="btn btn-primary d-block px-2 py-3"
							>
								Get Started
							</a> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 ftco-animate fadeInUp ftco-animated">
          <div className="block-7">
            {/* <div
								className="img"
								style="background-image: url(https://www.bootdey.com/image/350x280/87CEFA/000000);"
							></div> */}
            <div className="text-center p-4">
              <span className="excerpt d-block">Monthly Subscription</span>
              <span className="price">
                <sup>$</sup> <span className="number">99</span> <sub>/mos</sub>{' '}
                + 1.5% per new lead
              </span>
              <ul className="pricing-text mb-5">
                <li>
                  <span className="fa fa-check mr-2"></span>
                  Messaging
                </li>
                <li>
                  <span className="fa fa-check mr-2"></span>
                  Scheduling
                </li>
                <li>
                  <span className="fa fa-check mr-2"></span>
                  Negotiation
                </li>
                <li>
                  <span className="fa fa-check mr-2"></span>
                  Invoicing
                </li>
              </ul>
              {/* <a
								href=""
								className="btn btn-primary d-block px-2 py-3"
								onClick={() => scrollToSection("emailsection")}
							>
								Get Started
							</a> */}
            </div>
          </div>
        </div>
        <div className="col-md-4 ftco-animate fadeInUp ftco-animated">
          <div className="block-7">
            {/* <div
								className="img"
								style="background-image: url(https://www.bootdey.com/image/350x280/FF7F50/000000);"
							></div> */}
            {/* <div className="text-center p-4">
							<span className="excerpt d-block">
								Pay Per Lead
							</span>
							<span className="price">
								<sup>$</sup> <span className="number">109</span>{" "}
								<sub>/lead</sub>
							</span>
							<ul className="pricing-text mb-5">
								<li>
									<span className="fa fa-check mr-2"></span>
									Messaging
								</li>
								<li>
									<span className="fa fa-check mr-2"></span>
									Scheduling
								</li>
								<li>
									<span className="fa fa-check mr-2"></span>
									Negotiation
								</li>
								<li>
									<span className="fa fa-check mr-2"></span>
									Invoicing
								</li>
							</ul>
							<a
								href="#"
								className="btn btn-primary d-block px-2 py-3"
							>
								Get Started
							</a>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Makemoneyhero
