// src/pages/PrivacyPolicy.js
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Header from '../Header'
import Footer2 from '../Footer2'

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy - Redi</title>
        <meta
          name="description"
          content="Privacy Policy for Redi - The Real Estate Platform for Investors & Pros."
        />
      </Helmet>

      <div className="container my-5">
        <h1>Privacy Policy</h1>
        <p>
          Last modified: September 10, 2024 Introduction Redi Platforms, LLC, a
          Delaware limited liability company (“Company”, “we”, “us”, or “our”)
          respects your privacy and is committed to protecting it through our
          compliance with this policy. This policy describes the types of
          information we may collect from you or that you may provide when you
          access or use the Redi Platforms’ applications, including any content,
          products, functionality, and services offered on or through the
          foregoing or through www.maderedi.com (collectively the “Redi
          Interfaces”), whether as a guest, consumer, or a registered user, and
          our practices for collecting, using, maintaining, protecting, and
          disclosing such information. This policy applies to information we
          collect: Through any of the Redi Interfaces. In email, text, or other
          electronic messages between you and the Company. Through mobile and
          desktop applications published by Company that you may download from,
          or that compose, any of the Redi Interfaces. When you interact with
          our advertising and applications on third-party websites and services
          if those applications or advertising include links to this policy. It
          does not apply to information collected by: Us offline or through any
          other means, including on any other website operated by Company or any
          third party; or Any third party, including through any application or
          content (including advertising) that may link to or be accessible from
          or through the Redi Interfaces. Please read this policy carefully to
          understand our policies and practices regarding your information and
          how we will treat it. If you do not agree to the terms of this policy,
          you must not access or use any of the Redi Interfaces and further
          agree to delete any copies or instances thereof immediately. By
          accessing, using, or downloading any of the Redi Interfaces, you agree
          to this privacy policy and further agree to be bound by the terms
          incorporated herein. This policy may change from time to time (see
          Changes to Our Privacy Policy Section below). Your continued use of
          the Redi Interfaces after we make changes is deemed to be acceptance
          of those changes, so please check the policy periodically for updates.
          Terms not otherwise defined herein shall have the meaning ascribed to
          them in the Terms of Use, found on one or more of the Redi Interfaces
          websites or applications and incorporated herein as if fully set
          forth. Persons Under the Age of 18 The Redi Interfaces are offered and
          available to users who are 18 years of age or older and reside in the
          United States or any of its territories. In order to create an account
          through any of the Redi Interfaces, you must be 18 years of age or
          older. Our Redi Interfaces are not intended for persons under 18 years
          of age. No one under age 18 may provide any information to the Company
          or on or through the Redi Interfaces. We do not knowingly collect
          personal information from persons under 18. If you are under 18, do
          not use or provide any information on or through the Redi Interfaces
          or through any of its features, register on the Redi Interfaces, use
          any of the interactive or public features of the Redi Interfaces that
          may be available from time to time, or provide any information about
          yourself to us, including your name, address, telephone number, email
          address, or any screen name or username you may use. If we learn we
          have collected or received personal information from anyone under 18
          without verification of parental consent, we will delete such
          information. If you believe we might have any information from or
          about a person under 18, please contact us at the contact information
          provided below in the Contact Information Section herein. If any
          information collected by us at any time is governed by the Children’s
          Online Privacy Protection Act (“Coppa”), a U.S. law designed to
          protect the online privacy of children under the age of 13, we will
          use commercially reasonable efforts to fully comply with Coppa before
          asking for any personal information of a minor unless such information
          falls under an exception to Coppa. We do not intentionally collect any
          personal information relating to a minor at any time, for any reason,
          nor do we enable children to create accounts with our Redi Interfaces
          or permit access thereto without parental consent. Parents may contact
          us at the contact information provided below in the Contact
          Information Section if a parent believes we possess any personal
          information belonging to or related to their child and we will review,
          update, or delete any such information within a reasonable time
          following such request. Information We Collect About You and How We
          Collect It We collect several types of information from and about
          users of our Redi Interfaces, including when you contact us, which may
          include the following information: By which you may be personally
          identified, such as name, postal address, email address, and telephone
          number (“personal information”); About your activity on our Redi
          Interfaces; Payment information you provide to us for one-time and/or
          recurring services; Which may be considered sensitive information as
          defined by one or more applicable laws, which may include, in addition
          to what has already been listed above, account login credentials and
          precise geolocation, but excluding information such as medical
          information, racial or ethnic origin, political or religious beliefs,
          or sexuality or gender; That is about you but individually does not
          identify you; and/or About your internet connection, the equipment you
          use to access our Redi Interfaces, and usage details. We collect this
          information: Directly from you when you provide it to us, enter it
          into the Redi Interfaces, and send it to us. Automatically as you
          navigate to or through the Redi Interfaces. Information collected
          automatically may include usage details, IP addresses, and information
          collected through cookies and potentially other tracking technologies
          such as web beacons. From publicly available websites or third-party
          providers. Information You Provide to Us The information we collect on
          or through our Redi Interfaces may include: Information that you
          provide by filling in forms on our Redi Interfaces. This includes
          information provided at the time of registering to use our Redi
          Interfaces, creating or updating a profile, subscribing to our
          services, posting material, or requesting further services. We may
          also ask you for information when we interact with you, including when
          you use our Redi Interfaces, enter a contest or promotion sponsored by
          us, or when you report a problem with our Redi Interfaces. Records and
          copies of your correspondence (including email addresses and other
          contact information), if you contact us. Your responses to surveys
          that we might ask you to complete for research purposes. Details of
          transactions or interactions you carry out through our Redi
          Interfaces. You also may provide information to be published or
          displayed (hereinafter, “posted”) on public areas of the Redi
          Interfaces that may be created from time to time or transmitted to
          other users of the Redi Interfaces or third parties (collectively,
          “User Contributions”). Your User Contributions may be posted on and/or
          transmitted to others at your own risk. Although we limit access to
          user accounts, preferences, and transactions, please be aware that no
          security measures are perfect or impenetrable. Additionally, we cannot
          control the actions of other users of the Redi Interfaces with whom
          you may choose to share your User Contributions. Therefore, we cannot
          and do not guarantee that your User Contributions will not be viewed
          by unauthorized persons. Information We Collect Through Automatic Data
          Collection Technologies As you navigate through and interact with our
          Redi Interfaces, we may use automatic data collection technologies to
          collect certain information about your equipment, browsing actions,
          and patterns, including: Details of your visits to our Redi
          Interfaces, including traffic data, location data, logs, labels, and
          other communication data and the resources that you access and use on
          the Redi Interfaces. Information about your computer and internet
          connection, IP address, operating system, wi-fi access points, cell
          towers, blue-tooth enabled devices, and browser type. The information
          we collect automatically may include personal information or we may
          maintain it or associate it with personal information we collect in
          other ways or receive from third parties. It helps us to improve our
          Redi Interfaces and to deliver a better and more personalized service,
          including by enabling us to: Estimate our audience size and usage
          patterns. Store information about your preferences, allowing us to
          customize our Redi Interfaces according to your individual interests.
          Speed up your searches. Recognize you when you return to our Redi
          Interfaces. The technologies we use for this automatic data collection
          may include: Cookies (or browser cookies). A cookie is a small file
          placed on the hard drive of your computer. You may refuse to accept
          browser cookies by activating the appropriate setting on your browser.
          However, if you select this setting, you may be unable to access
          certain parts of our Redi Interfaces. Unless you have adjusted your
          browser setting so that it will refuse cookies, our system will issue
          cookies when you direct your browser to our Redi Interfaces. Web
          Beacons. Pages of our Redi Interfaces may contain small electronic
          files known as web beacons (also referred to as clear gifs, pixel
          tags, and single-pixel gifs) that permit the Company, for example, to
          count users who have visited certain pages or features and for other
          related statistics (for example, recording the popularity of certain
          content and verifying system and server integrity). Flash Cookies.
          Certain features of our Redi Interfaces may use local stored objects
          (or Flash cookies) to collect and store information about your
          preferences and navigation to, from, and on our Redi Interfaces. Flash
          cookies are not managed by the same browser settings as are used for
          browser cookies. Third-Party Use of Cookies and Other Tracking
          Technologies Some content or applications, including advertisements,
          on the Redi Interfaces are served by third-parties, including
          advertisers, ad networks and servers, content providers, and
          application providers. These third parties may use cookies alone or in
          conjunction with web beacons or other tracking technologies to collect
          information about you when you use our Redi Interfaces. The
          information they collect may be associated with your personal
          information or they may collect information, including personal
          information, about your online activities over time and across
          different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content. We do not control these third
          parties' tracking technologies or how they may be used. If you have
          any questions about an advertisement or other targeted content, you
          should contact the responsible provider directly. How We Use Your
          Information We use information that we collect about you or that you
          provide to us, including any personal information: To present our Redi
          Interfaces and its contents to you. To contact and communicate with
          you, including providing personalized, targeted, or location-based
          content, services, and advertising both from us and other
          third-parties. To analyze, improve, and optimize our services. To
          process financial transactions involving our services. To enforce the
          terms of our services, protect the rights of our users, and prevent
          fraud and abuse of our systems. To provide you with notifications,
          information, products, or services that you request from us. To
          provide you with notices about your account, which may include
          expiration and renewal notices. To carry out our obligations and
          enforce our rights arising from any contracts entered into between you
          and us, including for billing and collection. To notify you about
          changes to our Redi Interfaces or any services we (or third-parties)
          offer or provide though it. To allow you to participate in interactive
          features on our Redi Interfaces. To assist you with creating a
          profile. To inform you of other services available from us. To conduct
          surveys, promotions, and contests. To give search engines access to
          public information. To conduct analytics and generate reports about
          our Redi Interfaces. To fulfill any other purpose for which you
          provide it or for any other purpose with your consent. In any other
          way we may describe when you provide the information. We may also use
          your information to contact you about our own and third-parties' goods
          and services that may be of interest to you. We may use the
          information we have collected from you to enable us to display
          advertisements to our advertisers' target audiences. Even though we do
          not disclose your personal information for these purposes without your
          consent, if you click on or otherwise interact with an advertisement,
          the advertiser may assume that you meet its target criteria.
          Electronic Communications Notwithstanding any terms to the contrary
          herein, the Company may choose to electronically deliver
          communications to you through email, posting messages through the Redi
          Interfaces, updating this privacy policy or other policies on one or
          more of the Redi Interfaces, or through other reasonable methods. If
          the Company chooses to electronically deliver messages directly to
          you, you understand these messages may contain, transmit, or convey
          information about action taken either on your request or by or through
          your account. You agree to do business with the Company electronically
          through any of the above means and further agree to electronically
          receive notices, disclosures, communications, and information which
          the parties hereto stipulate shall satisfy any legal requirement to
          communicate in writing. An electronic notice will be deemed to have
          been received by you if sent via email upon sending by us. If you
          disagree with receiving electronic communications, you must
          immediately stop using the Redi Interfaces. You can unsubscribe from
          any marketing or promotional communications at any time. If you do not
          want to receive messages from us, please contact us at the information
          in the Contact Information section below and let us know.
          Unsubscribing from marketing or promotional communications will not
          remove your information from our contact list in the event we need to
          contact you for other purposes. Disclosure of Your Information We may
          disclose aggregated information about our users, and information that
          does not identify any individual, without restriction. We may disclose
          personal information that we collect, or you provide as described in
          this privacy policy: To our subsidiaries and affiliates. To
          contractors, service providers, and other third parties we use to
          support our business. To a buyer or other successor in the event of a
          merger, divestiture, restructuring, reorganization, dissolution, or
          other sale or transfer of some or all of our assets, whether as a
          going concern or as part of bankruptcy, liquidation, or similar
          proceeding, in which personal information held by us about our Redi
          Interfaces users is among the assets transferred. To investors or
          potential investors in our Company. To third parties to market their
          products or services to you if you have not opted out of these
          disclosures. To fulfill the purpose for which you provide it. To
          third-parties as required by law. With your consent. To comply with
          any court order, law, or legal process, including to respond to any
          government or regulatory request. To enforce or apply our Terms of Use
          available on the Redi Interfaces and other agreements, including for
          billing and collection purposes. If we believe disclosure is necessary
          or appropriate to protect the rights, property, or safety of us, our
          customers, or others, including exchanging information with other
          companies and organizations for the purposes of fraud protection and
          credit risk reduction. For any other purpose disclosed by us when you
          provide the information. Choices About How We Use and Disclose Your
          Information We strive to provide you with choices regarding the
          personal information you provide to us. We have created mechanisms to
          provide you with the following control over your information: Tracking
          Technologies and Advertising. You can set your browser to refuse all
          or some browser cookies, or to alert you when cookies are being sent.
          If you disable or refuse cookies, please note that some parts of the
          Redi Interfaces may then be inaccessible or not function properly.
          Disclosure of Your Information for Third-Party Advertising. If you do
          not want us to share your personal information with unaffiliated or
          non-agent third parties for promotional purposes, you can opt-out by
          sending us an email with your request to us at the contact information
          provided below. We do not control third parties' collection or use of
          your information to serve interest-based advertising. However, these
          third parties may provide you with ways to choose not to have your
          information collected or used in this way. You can opt out of
          receiving targeted ads from members of the Network Advertising
          Initiative (“NAI”) on the NAI's website. Residents of certain states,
          such as California, Nevada, Colorado, Connecticut, Virginia, Oregon,
          Montana, Texas, Iowa, Indiana, Tennessee, Delaware, and Utah may have
          additional personal information rights and choices. Please see Your
          State Privacy Rights Section below for more information. Accessing,
          Correcting, and Deleting Your Personal Information You can review and
          change your personal information by logging into the Redi Interfaces
          and visiting your account profile page. You may also send us an email
          at the email address provided in the contact information below to
          request access to, correct, or delete any personal information that
          you have provided to us. We may not be able to delete your personal
          information except by also deleting your user account. We may not
          accommodate a request to change information if we believe the change
          would violate any law or legal requirement or cause the information to
          be incorrect. Please keep in mind that we will only comply with this
          obligation to the extent and in such frequency as required by
          applicable law. Before we grant you rights to access, correct, or
          delete personal information, we must verify your identity and, if we
          are unable to do so, we may restrict your rights to access, correct,
          or delete personal information. We agree to use commercially
          reasonable efforts to fulfill your request within 30 days, however, we
          reserve the right to extend this timeframe as necessary. If you delete
          your User Contributions from the Redi Interfaces, copies of your User
          Contributions may remain viewable in cached and archived pages or
          might have been copied or stored by other Redi Interfaces users.
          Proper access and use of information provided on the Redi Interfaces,
          including User Contributions, is governed by our Terms of Use found on
          our Redi Interfaces, incorporated herein as if fully set forth. We do
          not currently sell your sensitive personal information; however, you
          may have certain rights under applicable privacy laws to limit both
          the use of and sale of your personal information. In order to exercise
          these rights, to the extent applicable, please contact us at the
          contact information provided below. We will not discriminate against
          you for exercising any of your rights under any applicable privacy
          law, expressly including denying you goods or services, charging you
          different prices or rates, providing you with different levels or
          qualities of goods or services, or suggesting you may receive a
          different price or rate for goods or services. Further we are not
          required to and will not, give access to personal information to the
          extent that: we reasonably believe that giving access would pose a
          serious threat to the life, health, or safety of any individual, or to
          public health or public safety; giving access would have an
          unreasonable impact on the privacy of other individuals; the request
          for access is frivolous or vexatious; the information relates to
          existing or anticipated legal proceedings and the information would
          not be accessible in normal discovery procedures; giving access would
          reveal the intentions of us in relation to negotiations and this
          disclosure would prejudice those negotiations; denying access is
          required or authorized by or under a law or a court/tribunal order; we
          have reason to suspect that unlawful activity, or misconduct of a
          serious nature, that relates to our functions or activities has been,
          or may be engaged in; giving access would be likely to prejudice the
          taking of appropriate action in relation to the matter; giving access
          would be likely to prejudice one or more enforcement related
          activities conducted by, or on behalf of, an enforcement body; or
          giving access would reveal evaluative information generated within us
          in connection with a commercially sensitive decision-making process.
          If we refuse access to the information or correcting such information,
          written notice will be provided to you setting out: the reasons for
          the refusal (except to the extent that, having regard to the grounds
          for the refusal, it would be unreasonable to do so); the mechanisms
          available to complain about the refusal; and any other matter
          prescribed by the regulations. Your State Privacy Rights State
          consumer privacy laws may provide their residents with additional
          rights regarding our use of their personal information. These state
          consumer privacy laws may or may not be currently active and may or
          may not be applicable to our business or our Redi Interfaces, however,
          we will provide appropriate links to the current state laws to inform
          you of your potential rights. To learn more about consumer privacy
          rights in these states, including how to exercise such rights, visit
          the appropriate hyperlink below: California
          https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5
          Colorado https://leg.colorado.gov/bills/sb21-190 Connecticut
          https://www.cga.ct.gov/asp/cgabillstatus/cgabillstatus.asp?selBillType=Bill&bill_num=SB00006&which_year=2022
          Delaware https://legiscan.com/DE/text/HB154/id/2807502 Indiana
          https://legiscan.com/IN/text/SB0005/id/2779850 Iowa
          https://www.legis.iowa.gov/legislation/BillBook?ga=90&ba=SF%20262
          Kentucky
          https://apps.legislature.ky.gov/law/acts/24RS/documents/0072.pdf
          Maryland https://mgaleg.maryland.gov/2024RS/bills/hb/hb0567E.pdf
          Minnesota
          https://www.revisor.mn.gov/bills/text.php?number=HF4757&type=bill&version=4&session=ls93&session_year=2024&session_number=0
          Montana
          https://laws.leg.mt.gov/legprd/LAW0210W$BSIV.ActionQuery?P_BILL_NO1=384&P_BLTP_BILL_TYP_CD=SB&Z_ACTION=Find&P_SESS=20231
          Nebraska
          https://nebraskalegislature.gov/FloorDocs/108/PDF/Slip/LB1074.pdf
          Nevada https://www.leg.state.nv.us/nrs/nrs-603a.html New Hampshire
          https://www.gencourt.state.nh.us/bill_status/billinfo.aspx?id=865&inflect=1
          New Jersey https://pub.njleg.state.nj.us/Bills/2022/AL23/266_.PDF
          Oregon
          https://olis.oregonlegislature.gov/liz/2023R1/Downloads/MeasureDocument/SB619/Enrolled
          Rhode Island https://legiscan.com/RI/text/S2500/2024 Tennessee
          https://legiscan.com/TN/text/HB1181/id/2672877 Texas
          https://capitol.texas.gov/BillLookup/Text.aspx?LegSess=88R&Bill=HB4
          Utah https://le.utah.gov/~2022/bills/static/SB0227.html Virginia
          https://law.lis.virginia.gov/vacodefull/title59.1/chapter53/ As this
          list is updated from time to time, and as these laws change, are
          modified, amended, or supplemented, or as new jurisdictions are added
          to this list, you may have certain rights and we remain committed to
          using commercially reasonable efforts to comply with. To exercise any
          of your rights available to you under your applicable state privacy
          rights law, please contact us at the contact information provided
          below. Retention Period We may retain your personal information for up
          to seven (7) years after the last time you access your account,
          following such time we will automatically delete it from our systems
          unless we have a legal obligation to keep it longer. If you would like
          us to delete your information, please contact us at the contact
          information provided below. Notwithstanding the foregoing, your
          information may be anonymized and kept for a longer period of time.
          Data Security We have implemented commercially reasonable measures
          designed to secure your personal information from accidental loss and
          from unauthorized access, use, alteration, and disclosure. We will
          endeavor to ensure our service providers have protection for
          electronic technology systems and other necessary restrictions when
          accessing the Redi Interfaces and data contained therein. We will
          endeavor to ensure our staff are trained with respect to security of
          personal information and logically restrict such data where
          appropriate. Subject to the terms and conditions set forth herein, we
          will endeavor to destroy and de-identify personal information once it
          is no longer required. In the event we hold any personal information
          that is unsolicited, or we were not permitted to collect it, such
          information will be destroyed as soon as practicable. The safety and
          security of your information also depends on you. Where we have given
          you (or where you have chosen) a password for access to certain parts
          of our Redi Interfaces, you are responsible for keeping this password
          confidential. We ask you not to share your password with anyone. We
          urge you to be careful about giving out information in public areas of
          the Redi Interfaces that may exist from time to time, like message
          boards. The information you share in public areas may be viewed by any
          user of the Redi Interfaces. Unfortunately, the transmission of
          information via the internet is not completely secure. Although we do
          our best to protect your personal information, we cannot guarantee the
          security of your personal information transmitted to or through our
          Redi Interfaces. Any transmission of personal information is at your
          own risk. We are not responsible for circumvention of any privacy
          settings or security measures contained on the Redi Interfaces.
          Changes to Our Privacy Policy It is our policy to post any changes we
          make to our privacy policy on this page. If we make material changes
          from our privacy policies as to how we treat our users' personal
          information, we will notify you by email, through a notice on the Redi
          Interfaces home page, or by posting an updated policy and bringing
          such updated policy to your attention using reasonable means and
          methods. The date the privacy policy was last revised is identified at
          the top of the page. You are responsible for ensuring we have an
          up-to-date active and deliverable email address for you, and for
          periodically visiting our Redi Interfaces and this privacy policy to
          check for any changes. Contact Information To ask questions or comment
          about this privacy policy and our privacy practices, to request an
          export of any data we may have on you or your account, to delete your
          data, or to exercise any additional rights you may have, please
          contact us at Support@maderedi.com.
        </p>
        {/* Add more content as needed */}
      </div>
      <Footer2 />
    </div>
  )
}

export default PrivacyPolicy
