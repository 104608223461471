import React from "react";
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer2 = () => {
	const scrollToSection = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<footer className="footer_area section_padding_130_0">
			<div className="container">
				<div className="row">
					<div className="col social">
						<div className="single-footer-widget socialsection_padding_0_130">
							<p>Always Here, Always Redi</p>
							<div className="footer_social_area social">
								<a
									href="#"
									data-toggle="tooltip"
									data-placement="top"
									title=""
									data-original-title="Facebook"
								>
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a
									href="#"
									data-toggle="tooltip"
									data-placement="top"
									title=""
									data-original-title="Twitter"
								>
									<FontAwesomeIcon icon={faTwitter} />
								</a>
								<a
									href="#"
									data-toggle="tooltip"
									data-placement="top"
									title=""
									data-original-title="Instagram"
								>
									<FontAwesomeIcon icon={faInstagram} />
								</a>
								<a
									href="#"
									data-toggle="tooltip"
									data-placement="top"
									title=""
									data-original-title="LinkedIn"
								>
									<FontAwesomeIcon icon={faLinkedin} />
								</a>
							</div>
							<p className="my-3 text-center">
								Redi Platforms, LLC
							</p>
						</div>
					</div>
					{/* <div className="col-12 col-sm-6 col-lg">
						<div className="single-footer-widget section_padding_0_130">
							<h5 className="widget-title">Home</h5>
							<div className="footer_menu">
								<ul>
									<li>
										<a href="#">Sign Up Early</a>
									</li>
									<li>
										<a
											href="#"
											onClick={() =>
												scrollToSection(
													"investorSection"
												)
											}
										>
											For Real Estate Investors
										</a>
									</li>
									<li>
										<a
											href="#"
											onClick={() =>
												scrollToSection(
													"serviceProSection"
												)
											}
										>
											For Service Providers
										</a>
									</li>
									<li>
										<a
											href="#"
											onClick={() =>
												scrollToSection(
													"pricingSection"
												)
											}
										>
											Pricing
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-lg">
						<div className="single-footer-widget section_padding_0_130">
							<h5 className="widget-title">Support</h5>
							<div className="footer_menu">
								<ul>
									<li>
										<a href="#">Help</a>
									</li>
									<li>
										<a href="#">Support</a>
									</li>
									<li>
										<a href="#">Privacy Policy</a>
									</li>
									<li>
										<a href="#">Term & Conditions</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-lg">
						<div className="single-footer-widget section_padding_0_130">
							<h5 className="widget-title">Contact</h5>
							<div className="footer_menu">
								<ul>
									<li>
										<a href="#">Email Us</a>
									</li>
								</ul>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</footer>
	);
};

export default Footer2;
