import React from "react";
import Tiltphone from "../Images/Branding/Tiltphone.png";
import Biddocument from "../Images/Features/Biddocument.png";

const Brandinghero = () => {
	return (
		<div className="text-center ">
			<div className="container brandingsection branding-container">
				<div className="row">
					<div className="col-md-6 heroimg">
						<h1 className="display-5 lilitaoneblack">
							Property Maintenance & Renovation Made Easy:
						</h1>
						<h1 className="display-3 lilitaoneblack2">
							find, hire, & manage service pros
						</h1>
						<p>
							Streamline the renovation and maintenance process
							for your investment property. Submit work orders,
							sign contracts, and schedule services hassle-free.
							Stay in the loop with progress updates, receive
							invoices, and remit payments –{" "}
							<strong>all in one place.</strong>
						</p>
						<img className="biddocument" src={Biddocument}></img>

						<h2 className="lilitaonered my-5">
							Always Here, Always Redi
						</h2>
					</div>
					<div className="col-md-6 brandingvalueprop">
						<img className="phonetilt" src={Tiltphone} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Brandinghero;
