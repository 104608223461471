import { Helmet } from 'react-helmet'

import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom' // Import BrowserRouter
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css'
import Header from './Corecomponent/Header'
import EmailHero from './Corecomponent/Emailhero'
import Brandinghero from './Corecomponent/Brandinghero'
import FeaturesHero from './Corecomponent/Featurehero'
import Serviceproshero from './Corecomponent/Serviceproshero'
import Investorhero from './Corecomponent/Investorhero'
import Makemoneyhero from './Corecomponent/Makemoneyhero'
import Footer2 from './Corecomponent/Footer2'
import PrivacyPolicy from './Corecomponent/Pages/privacy-policy'

function App() {
  const helmetContext = {}

  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Router>
          {' '}
          {/* Wrap your components with Router */}
          <Header /> {/* Header now within Router context */}
          <Routes>
            {/* Main/Home route */}
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>
                      Redi - The Real Estate Platform for Investors & Pros
                    </title>
                    <meta
                      name="description"
                      content="Redi - The Real Estate Platform for Investors & Service Pros"
                    />
                    <meta
                      property="og:image"
                      content="%PUBLIC_URL%/Redilogo.png"
                    />
                  </Helmet>
                  <Brandinghero />
                  <EmailHero />
                  <FeaturesHero />
                  <Serviceproshero />
                  <Investorhero />
                  <Makemoneyhero />
                  <Footer2 />
                </>
              }
            />

            {/* Privacy Policy route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  )
}

export default App
