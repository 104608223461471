import React, { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '../firebase'

const EmailHero = () => {
  const [email, setEmail] = useState('')
  const [userType, setUserType] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const docRef = await addDoc(collection(db, 'emails'), {
        email,
        userType,
        date: new Date(),
      })
      console.log('Document written with ID: ', docRef.id)
    } catch (e) {
      console.error('Error adding document: ', e)
    }
    setSubmitted(true)
  }

  return (
    <div
      className="container emailhero text-white text-center py-3"
      id="emailsection"
    >
      <h1 className="display-4 lilitaonewhite">Sign Up Early</h1>
      <p className="lead">
        Get the latest updates on our launch right in your inbox.
      </p>
      {submitted ? (
        <p className="lilitaonewhite">🎉 Thank you for joining! 🎉</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="input-group mb-3">
            <select
              className="form-control signupearly"
              id="userType"
              onChange={handleUserTypeChange}
              value={userType}
              required
            >
              <option id="imhere" value="">
                I'm here as a....👇🏽
              </option>
              <option value="servicePro">I'm a Service Pro</option>
              <option value="investor">
                I'm a Real Estate Investor who owns rental units
              </option>
              <option value="homeowner">Homeowner</option>
            </select>
            <input
              type="email"
              className="form-control signupearly"
              placeholder="Enter your email ✉️"
              aria-label="Enter your email"
              aria-describedby="subscribe-button"
              value={email}
              onChange={handleEmailChange}
              required
            />

            <div className="input-group-append">
              <button
                className="btn btn-light submitbutton"
                type="submit"
                id="subscribe-button"
              >
                Join Waitlist
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default EmailHero
