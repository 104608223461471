import React from "react";
import provideriphonehold from "../Images/Branding/provideriphonehold.png";
import Ongoinglist from "../Images/Features/Ongoinglist.png";

const Investorhero = () => {
	return (
		<div
			className="container serviceproviderherosection"
			id="investorSection"
		>
			<div className="row branding-containervideo">
				<div className="col-md-4 herovideo">
					<h1 className="videoandimagediv lilitaonered">
						For Real Estate Investors
					</h1>
					{/* <iframe
						className="videoandimagediv iframe"
						src="https://www.youtube.com/embed/LMlCN6_vUvs?si=W3G8Fqb26HlVi-4B"
						title="YouTube video player"
						frameBorder=""
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					></iframe> */}
					<div className="videoandimagediv">
						<img className="investorpicture" src={Ongoinglist} />
						<p>
							Streamline your maintenance and renovation process
							for your investment property with our platform's
							best-in-class features. Submit a work order, execute
							contracts, schedule your services, receive invoices,
							and pay - all in one convenient location.
						</p>
					</div>
				</div>
				<div className="col-md-8 phonedislay">
					<img className="servicephone" src={provideriphonehold} />
				</div>
			</div>
		</div>
	);
};

export default Investorhero;
