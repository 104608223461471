import React, { useState } from 'react'
import { Link } from 'react-router-dom' // Import Link from react-router-dom
import RediLogo from '../Images/Branding/RediLogo.png'

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen)
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <a className="navbar-brand" href="">
          <img src={RediLogo} width="100" height="" />
        </a>
        <button className="navbar-toggler" type="button" onClick={toggleNav}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="maderedi.com"
                onClick={() => scrollToSection('serviceProSection')}
              >
                For Service Providers
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => scrollToSection('investorSection')}
              >
                For Real Estate Investors
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="maderedi.com"
                onClick={() => scrollToSection('pricingSection')}
              >
                Pricing
              </a>
            </li>
            {/* Add the Privacy Policy Tab */}
            <li className="nav-item">
              <Link className="nav-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            {/* Uncomment if you want to use the Reviews section later */}
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
                Reviews
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
