import React from "react";
import bidrequest from "../Images/Icons/bidrequest.png";
import communication from "../Images/Icons/communication.png";
import contractexecution from "../Images/Icons/contractexecution.png";
import invoicingpayment from "../Images/Icons/invoicingpayment.png";
import scheduling from "../Images/Icons/scheduling.png";
import Escrow from "../Images/Features/Escrow.png";
import Addproperty from "../Images/Features/Addproperty.png";
import Specialty from "../Images/Features/Specialty.png";
import Shield from "../Images/Icons/Shield.png";

const FeaturesHero = () => {
	return (
		<div className="container featureherosection text-center">
			<h1>Our Best Features</h1>

			<p className="subtitles text-center">
				Designed for service professionals & real estate investors.
				<br />
				Built by real estate professionals & experts.
				<strong className="strong">
					{" "}
					<br />
					{/* Carefully collaborated with 200+ users to create the most
					optimal solution! */}
				</strong>
			</p>
			<div className="feature-blocks row">
				<div className="feature col-md-4 col-sm-6">
					<div className="headersize">
						<img className="icon" src={bidrequest}></img>
					</div>
					<div className="headersize">
						<h2 className="featureitemheader">
							Good Faith Deposit
						</h2>
					</div>
					<p>
						Clients can express intent by adding money before
						projects are started
					</p>
				</div>
				<div className="feature col-md-4 col-sm-6">
					<div className="headersize">
						<img className="icon" src={contractexecution}></img>
					</div>
					<div className="headersize">
						<h2 className="featureitemheader">
							Contract Execution
						</h2>
					</div>
					<p>
						Digitally execute service contracts and approve change
						orders.
					</p>
				</div>
				<div className="feature col-md-4 col-sm-6">
					<div className="headersize">
						<img className="icon" src={Shield}></img>
					</div>
					<div className="headersize">
						<h2 className="featureitemheader">
							Qualified, Licensed, & Insured Pros
						</h2>
					</div>
					<p>
						Work with pros who are fully qualified with insurance
						coverage
					</p>
				</div>
				<div className="feature col-md-4 col-sm-6">
					<div className="headersize">
						<img className="icon" src={communication}></img>
					</div>
					<div className="headersize">
						<h2 className="featureitemheader">Communication</h2>
					</div>
					<p>
						Review progress and communicate directly with service
						providers.
					</p>
				</div>
				<div className="feature col-md-4 col-sm-6">
					<div className="headersize">
						<img className="icon" src={invoicingpayment}></img>
					</div>
					<div className="headersize">
						<h2 className="featureitemheader">
							Invoicing & Payment
						</h2>
					</div>
					<p>Receive/send invoices and make payments seamlessly.</p>
				</div>
				<img className="speciality" src={Specialty}></img>
				<img className="escrow" src={Escrow}></img>
				<img className="addproperty" src={Addproperty}></img>
			</div>
		</div>
	);
};

export default FeaturesHero;
